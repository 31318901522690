import React from 'react'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'

const BlockTextOnly = ({ content }) => {
  const { title, description, description2, link } = content
  return (
    <section className='block-text-only'>
      <div className='container'>
        <div className='text'>
          <Inview className='fade-in up'>
            <h2>{title}</h2>
          </Inview>
          <Inview className='fade-in up'>
            <p className='larger'>{description}</p>
          </Inview>
          {description2 && (
            <Inview className='fade-in up'>
              <p className='larger'>{description2}</p>
            </Inview>
          )}
          <Inview className='fade-in up'>
            <LineLink content={link} />
          </Inview>
        </div>
      </div>
    </section>
  )
}

export default BlockTextOnly
