import React from 'react'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import { content, filterContentByLocale } from 'content/content.js'
import BlockBannerSlideshow from 'components/blocks/BlockBannerSlideshow.js'
import BlockTextOnly from 'components/blocks/BlockTextOnly'
import BlockIconTextTable from 'components/blocks/BlockIconTextTable'
import BlockSlideshow from 'components/blocks/BlockSlideshow'
import BlockBannerMid from 'components/blocks/BlockBannerMid'
import BlockTextImageNumbers from 'components/blocks/BlockTextImageNumbers'
import LineHeadline from 'components/LineHeadline'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

// markup
const HomePage = () => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { home, footer } = localeContent

  const {
    banner,
    textOnly,
    table,
    slideshow,
    bannerMid,
    titleStandalone,
    textImageNumbers,
    textImageNumbers2,
  } = home

  let seoContent = {
    title: {
      en: `Technologies Devoted to Safety`,
      zh: `展科技之翼，用心构筑安全`,
    },
    description: {
      en: '***',
      zh: '我们是建筑安全系统解决方案的提供商，运用物联网技术，构建智慧安全集成体系，为建筑提供智能产品、智慧建造以及全生命周期的安全运行和管理服务。',
    },
  }
  seoContent = filterContentByLocale(seoContent, locale)

  return (
    <div className='page'>
      <Seo content={seoContent} />
      <div className='bg-gradient'>
        <BlockBannerSlideshow content={banner} />
        <BlockTextOnly content={textOnly} />
        <BlockIconTextTable content={table} />
        <BlockSlideshow content={slideshow} />
      </div>
      <div className='bg-gradient-reverse'>
        <BlockBannerMid content={bannerMid} isShorterMobile={true} />
        <Inview className='container home-line-headline fade-in up'>
          <LineHeadline text={titleStandalone} />
        </Inview>
        <BlockTextImageNumbers content={textImageNumbers} />
        <BlockTextImageNumbers content={textImageNumbers2} isReversed={true} />
        <Inview className='container home-line-link fade-in up'>
          <LineLink content={{ to: '/solutions/' }} />
        </Inview>
        <Footer content={footer.default} />
      </div>
    </div>
  )
}

export default HomePage
