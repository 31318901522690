import React, { useState } from 'react'
import Image from 'components/Image'
import LineLink from 'components/LineLink'
import LineHeadline from 'components/LineHeadline'
import SwiperCore, {
  Autoplay,
  Navigation,
  Controller,
  EffectFade,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Inview from 'components/Inview'

SwiperCore.use([Autoplay, Navigation, Controller, EffectFade, Pagination])

const BlockSlideshow = ({ content }) => {
  const { title, slides, link } = content

  return (
    <section className='block-slideshow-compare'>
      <div className='container'>
        <Inview className='fade-in up'>
          <LineHeadline text={title} />
        </Inview>
        <SwiperComponent slides={slides} />
        <Inview className='fade-in up'>
          <LineLink content={link} />
        </Inview>
      </div>
    </section>
  )
}

export default BlockSlideshow

const SwiperComponent = ({ slides }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null)

  return (
    <div className='swiper-component'>
      <Inview className='swiper-images fade-in up'>
        <Swiper
          controller={{ control: controlledSwiper }}
          loop={true}
          loopedSlides={slides.length}
          loopAdditionalSlides={1}
          speed={600}
          autoplay={{
            delay: 5500,
          }}
          pagination={{ clickable: true }}
          slideToClickedSlide={true}
          spaceBetween={16}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            601: {
              slidesPerView: 1.5,
            },
            1025: {
              slidesPerView: 2.5,
            },
          }}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <ImageSlide slide={slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Inview>
      <Inview className='swiper-text fade-in up'>
        <Swiper
          onSwiper={setControlledSwiper}
          allowTouchMove={false}
          loop={true}
          loopedSlides={slides.length}
          loopAdditionalSlides={1}
          effect='fade'
          spaceBetween={16}
          speed={600}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            601: {
              slidesPerView: 1.5,
            },
            1025: {
              slidesPerView: 2.5,
            },
          }}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <TextSlide content={slide} key={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Inview>
    </div>
  )
}

const ImageSlide = ({ slide }) => {
  return (
    <div className='image-wrap'>
      <Image filename={slide.image} />
    </div>
  )
}

const TextSlide = ({ content }) => {
  const { title, excerpt } = content
  return (
    <div className='inner'>
      <h4>{title}</h4>
      <p>{excerpt}</p>
    </div>
  )
}

// css
