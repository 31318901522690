import React from 'react'
import LinkWrap from 'components/LinkWrap'
import { useLocalization } from 'gatsby-theme-i18n'

const LineLink = ({ content }) => {
  let { to, text } = content
  const { locale } = useLocalization()
  const fallback = {
    en: 'Learn More',
    zh: '了解更多',
  }
  if (!text) text = fallback[locale]
  return (
    <LinkWrap to={to} className='component-line-link'>
      <span>{text}</span>
    </LinkWrap>
  )
}

export default LineLink
