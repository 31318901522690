import React from 'react'
import LineLink from 'components/LineLink'
import LineHeadline from 'components/LineHeadline'
import Image from 'components/Image'
import Inview from 'components/Inview'

const BlockIconTextTable = ({ content }) => {
  const { title, items, link } = content
  return (
    <section className='block-icon-text-table'>
      <div className='container'>
        <Inview className='fade-in up'>
          <LineHeadline text={title} />
        </Inview>
        <div className='table'>
          {items.map((item, i) => (
            <Item content={item} key={i} />
          ))}
        </div>
        <Inview className='fade-in up'>
          <LineLink content={link} />
        </Inview>
      </div>
    </section>
  )
}

export default BlockIconTextTable

const Item = ({ content }) => {
  const { icon, title, description } = content
  return (
    <Inview className='item fade-in up'>
      <div className='icon'>
        <Image filename={icon} />
      </div>
      <div className='text'>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </Inview>
  )
}
