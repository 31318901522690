import React, { useState } from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay, EffectFade, Parallax } from 'swiper'

SwiperCore.use([EffectFade])
SwiperCore.use([Autoplay])

const BlockBannerSlideshow = ({ content }) => {
  const [activeId, setActiveId] = useState(false)
  const { title, slides } = content
  SwiperCore.use([Navigation, Autoplay, EffectFade, Parallax])
  return (
    <section className='block-banner-slideshow block'>
      <Inview className='inner'>
        <ParallaxWrapBanner>
          <Images slides={slides} setActiveId={setActiveId} />
        </ParallaxWrapBanner>
        {/* <Text slides={slides} activeId={activeId} /> */}
        <TitleOnly title={title} />
        <Captions slides={slides} activeId={activeId} />
      </Inview>
    </section>
  )
}

export default BlockBannerSlideshow

const Images = ({ slides, setActiveId }) => {
  return (
    <div className='images'>
      <div className='fade-in'>
        <Swiper
          // effect='fade'
          autoplay={{
            delay: 6000,
            // disableOnInteraction: true,
          }}
          loopAdditionalSlides={2}
          speed={1200}
          onSwiper={(swiper) => setActiveId(swiper.activeIndex)}
          onSlideChange={(swiper) => setActiveId(swiper.activeIndex)}
          parallax={true}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <div data-swiper-parallax='50%'>
                <div className='image-wrap image-desktop'>
                  <Image filename={slide.image} />
                </div>
                <div className='image-wrap image-mobile'>
                  <Image filename={slide.imageMobile} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

// const Text = ({ slides, activeId }) => {
//   return (
//     <div className='text-box fade-in stagger-1000'>
//       <div className='items'>
//         {slides.map((slide, i) => {
//           return <TextSlide content={slide} key={i} i={i} activeId={activeId} />
//         })}
//       </div>
//     </div>
//   )
// }

// const TextSlide = ({ content, i, activeId }) => {
//   const { title } = content
//   return (
//     <div className={`text-slide ${activeId === i ? 'active' : ''}`}>
//       <h1>{title}</h1>
//     </div>
//   )
// }

const TitleOnly = ({ title }) => {
  return (
    <div className='text-box text-only fade-in stagger-1000'>
      <h1>{title}</h1>
    </div>
  )
}

const Captions = ({ slides, activeId }) => {
  return (
    <div className='captions fade-in stagger-1000'>
      <div className='items'>
        {slides.map((slide, i) => {
          return (
            <CaptionSlide
              content={slide}
              key={i}
              i={i}
              total={slides.length}
              activeId={activeId}
            />
          )
        })}
      </div>
    </div>
  )
}

const CaptionSlide = ({ content, i, total, activeId }) => {
  const { caption } = content
  return (
    <div className={`caption-slide ${activeId === i ? 'active' : ''}`}>
      <div className='line front' />
      <p>{caption}</p>
      <div className='line animate mid' />
      <div className='number-line-wrap'>
        <p>
          {i + 1} / {total}
          <span className='line animate after' />
        </p>
      </div>
    </div>
  )
}
