import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'
import Counter from 'components/Counter'

const BlockTextImageNumbers = ({ content, isReversed }) => {
  const { image, text } = content
  return (
    <section
      className={`block-text-image-numbers ${isReversed ? 'is-reversed' : ''}`}
    >
      <div className='container'>
        <Inview className='image fade-in fade-in up'>
          <div className='inner'>
            <Image filename={image} />
          </div>
        </Inview>
        <Inview className='text'>
          <div className='intro fade-in up'>
            <h2>{text.title}</h2>
            <p>{text.excerpt}</p>
          </div>
          <div className='numbers'>
            {text.numbers.map((item, i) => (
              <div className={`item fade-in up stagger-${i * 2 + 4}00`} key={i}>
                <h2>
                  <span className='number'>
                    <Counter target={item.number} duration={3} />
                  </span>
                  <span className='number-text'>{item.numberText}</span>
                  <span className='unit'>{item.unit}</span>
                </h2>
                <div className='lower'>
                  <div className='icon'>
                    <Image filename={item.icon} />
                  </div>
                  <p className='smaller'>{item.excerpt}</p>
                </div>
              </div>
            ))}
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default BlockTextImageNumbers
